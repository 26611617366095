<template>
    <div class="d-flex">
        <task-to-theme-report-form class="mx-auto w-100" style="width: 100%; max-width: 800px;" />
    </div>
</template>

<script>
import TaskToThemeReportForm from '@/components/forms/TaskToThemeReportForm.vue'

export default {
    components: { TaskToThemeReportForm }
}
</script>