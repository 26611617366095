<template>
    <div class="d-flex flex-column">
        <v-text-field 
            v-model="$v.form.year.$model"
            :error-messages="getErrors('form.year')"
            label="Календарный год" 
            name="year" 
            type="number" 
            placeholder=" "
            :color="$const.color.primary" 
        ></v-text-field>

        <v-select
            v-model="$v.form.subject.$model"
            :error-messages="getErrors('form.subject')"
            required
            :items="subjects"
            label="Предмет"
            @change="checkFormGradeAvailability"
        />

        <v-select
            v-model="$v.form.grade.$model"
            :disabled="!$v.form.subject.$model"
            :error-messages="getErrors('form.grade')"
            required
            :items="grades"
            label="Класс"
        />
        <div>
            <v-btn
                dark 
                small
                :color="$const.color.primary" 
                :loading="loading" 
                @click.prevent.stop="submit"
            >
                Отобразить
            </v-btn>
        </div>

        <v-divider class="my-5" />

        <v-alert
            v-if="serverErrors"
            dense
            type="error"
        >
            {{ serverErrors }}
        </v-alert>

        <template v-else>

            <p class="caption opacity-6">Таблица отображает темы, которые есть только в ВПР, но нет в КР, либо наоборот.</p>

            <v-data-table
                dense
                disable-pagination
                disable-filtering
                hide-default-footer 
                :calculate-widths="true"
                :headers="[
                    { text: 'Тема', value: 'name', width: 1 },
                    { text: 'ID задания ВПР', value: 'test', width: 1 },
                    { text: 'ID задания КР', value: 'control', width: 1 },
                ]"
                :items="results"
                class="elevation-0"
            />
        </template>
    </div>
</template>

<script>
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    mixins: [errorMixin, saveMixin, validationMixin],
    data () {
        return {
            results: [],
            loading: false,
            form: {
                year: (new Date()).getFullYear(),
                subject: null,
                grade: null
            }
        }
    },
    validations() {
        return {
            form: {
                year: { required },
                subject: { required },
                grade: { required }
            }
        }
    },
    computed: {
        subjects () {
            return this.$store.state.app.subjects
        },
        grades () {
            if (!this.form.subject) { return [] }
            return this.subjects.find(item => item.value === this.form.subject)?.grades.flat()
        }
    },
    methods: {
        async submit () {
            try {

                if (this.loading) { return false; }

                if (!this.validate()) {
                    console.log('Validation failed')
                    return false;
                }
            
                this.loading = true
                
                const { success, data, error } = await this.$store.dispatch('report/themeDifference', this.form)
                
                if (!success) throw new Error(error)
                
                this.results = data
            
            } catch (e) {
            
                console.error(e)

                this.serverErrors = e
            
            } finally {
            
                this.loading = false
            
            }
        },
        checkFormGradeAvailability () {

            if (!this.grades.includes(this.form.grade))
                this.form.grade = null
        }
    }
}
</script>